<template>
  <ServiceComboFormPart
    :service-selections="serviceSelections"
    v-on="$listeners"
  />
</template>

<script>
import {
  officeOfNotaryDivorceConsumerNL,
  officeOfNotaryDivorceCorporateNL,
  officeOfNotaryHouseAndMortgageConsumerNL,
  officeOfNotaryHouseAndMortgageCorporateNL,
  officeOfNotaryLegalEntitiesConsumerNL,
  officeOfNotaryLegalEntitiesCorporateNL,
  officeOfNotaryOtherServicesConsumerNL,
  officeOfNotaryOtherServicesCorporateNL,
  officeOfNotaryWeddingAndCohabitationConsumerNL,
  officeOfNotaryWeddingAndCohabitationCorporateNL,
  officeOfNotaryWillAndInheritanceConsumerNL,
  officeOfNotaryWillAndInheritanceCorporateNL,
} from 'chimera/officeOfNotary/service'
import ServiceComboFormPart from 'chimera/all/themes/blueflow/components/form/part/service/ServiceComboFormPart'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    ServiceComboFormPart,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      serviceSelections: [
        new Selectable(
          'Erfenis / Testament',
          'Erfenis / Testament',
          'Erfenis / Testament',
          {
            consumer: officeOfNotaryWillAndInheritanceConsumerNL,
            corporate: officeOfNotaryWillAndInheritanceCorporateNL,
          },
        ),
        new Selectable(
          'Trouwen / Samenleven',
          'Trouwen / Samenleven',
          'Trouwen / Samenleven',
          {
            consumer: officeOfNotaryWeddingAndCohabitationConsumerNL,
            corporate: officeOfNotaryWeddingAndCohabitationCorporateNL,
          },
        ),
        new Selectable('Onroerend Goed', 'Onroerend Goed', 'Onroerend Goed', {
          consumer: officeOfNotaryHouseAndMortgageConsumerNL,
          corporate: officeOfNotaryHouseAndMortgageCorporateNL,
        }),
        new Selectable('Rechtspersonen', 'Rechtspersonen', 'Rechtspersonen', {
          consumer: officeOfNotaryLegalEntitiesConsumerNL,
          corporate: officeOfNotaryLegalEntitiesCorporateNL,
        }),
        new Selectable('Scheiden', 'Scheiden', 'Scheiden', {
          consumer: officeOfNotaryDivorceConsumerNL,
          corporate: officeOfNotaryDivorceCorporateNL,
        }),
        new Selectable('Overig', 'Overig', 'Overig', {
          consumer: officeOfNotaryOtherServicesConsumerNL,
          corporate: officeOfNotaryOtherServicesCorporateNL,
        }),
      ],
    }
  },
}
</script>
